<template>
  <section class="w-100 f6 mt0-l mt4 ph4-l ph3">
    <article class="bg-white mh5-l mh0">
      <div class="ph5-l ph4-m ph2 pv3">
        <div class="tc">
          <div class="ba b--light-gray bw2">
            <h1 class="tc f3 f2-ns ttu vidaloka">
              {{ $t("navigation.myOrders") }}
            </h1>
          </div>
        </div>
        <div v-if="error" class="dark-red">{{ error }}</div>

        <van-loading v-if="loading" />

        <!-- isAuthenticated? -->
        <is-authenticated
          v-if="!authenticated"
          :authenticated="authenticated"
        />
        <div v-else-if="isEmpty">
          <p>nothing purchased</p>
        </div>
        <div v-else-if="purchases">
          <purchase
            v-for="purchase in purchases"
            :key="purchase.id"
            :purchase="purchase"
          />
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Purchase from "../components/Purchase.vue";
import IsAuthenticated from "../components/IsAuthenticated.vue";

export default {
  name: "purchased-products-view",
  components: {
    purchase: Purchase,
    "is-authenticated": IsAuthenticated
  },
  computed: {
    ...mapGetters("purchase", {
      error: "isError",
      loading: "isLoading",
      purchases: "purchases"
    }),
    ...mapGetters("auth", {
      authenticated: "authenticated"
    }),
    isEmpty() {
      return this.purchases[0] === false;
    }
  },
  methods: {
    fetchPurchasedProducts() {
      return this.$store.dispatch("purchase/purchasedProducts", {
        cookie: this.$cookie,
        language: this.$route.params.lang
      });
    }
  },
  mounted() {
    if (this.authenticated) {
      this.fetchPurchasedProducts();
    } else {
      this.$router.push({ name: "signIn" });
    }
  },
  watch: {
    $route: "fetchPurchasedProducts",
    authenticated(newValue, oldValue) {
      if (!newValue) {
        this.$router.push({ name: "signIn" });
      }
    }
  }
};
</script>
