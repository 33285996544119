<template>
  <div v-if="!authenticated">
    Kirjaudu.....
  </div>
  <div v-else></div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "note",
  props: {
    authenticated: Boolean
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  created() {},
  mounted() {},
  components: {}
};
</script>
