<template>
  <div class="w-100">
    <div class="flex flex-wrap">
      <div class="w-50">
        <!-- name, status and order_id -->
        <router-link
          :to="
            $i18nRoute({
              name: 'product',
              params: { sku: purchase.product.sku }
            })
          "
          ><h2 class="f4-ns f6 fw6 mb0">
            {{ purchase.product.name }}
          </h2></router-link
        >
        <p class="f7 measure lh-copy ma0 pa0">
          {{ $t("purchase.status") }}
          {{ $t("order_status." + purchase.status.toLowerCase()) }}
        </p>
      </div>
      <!-- price -->
      <div class="w-10 flex items-center">
        <h2 class="f5-ns f6 fw6">
          {{ purchase.product.price }} {{ purchase.product.currency }}
        </h2>
      </div>

      <div class="w-40 flex items-center justify-center">
        <open-list
          :type="purchase.product.type"
          :sku="purchase.product.sku"
          :productId="purchase.product.id"
        />
      </div>

      <!-- LineBottom -->
      <div class="w-100">
        <hr class="b--black-10" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import OpenList from "./OpenList.vue";

export default {
  name: "purchase",
  props: {
    purchase: Object
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  created() {},
  mounted() {},
  components: {
    "open-list": OpenList
  }
};
</script>
